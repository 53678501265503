//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  name: "articleDetail",

  data() {
    return {
      // last:'',
      ruleForm: {},
      html_data: '',
      // module:'新闻中心',
      path: '/news',
      linguisticType: localStorage.getItem('linguisticType')
    };
  },

  computed: {
    activeMenu() {
      const route = this.$route;
      const {
        meta
      } = route;
      return meta.title;
    },

    ...mapState({
      device: state => state.device
    })
  },
  watch: {
    $route: function (to, from) {
      if (this.$route.query.params) {
        this.ruleForm = JSON.parse(this.$route.query.params);
        this.ruleForm.releaseDate = this.ruleForm.releaseDate.substring(0, 10);
      }
    }
  },

  created() {
    if (this.$route.query.params) {
      this.ruleForm = JSON.parse(this.$route.query.params);
      console.log('接收的值', this.ruleForm);
      this.ruleForm.releaseDate = this.ruleForm.releaseDate.substring(0, 10);
    }

    if (this.$route.query.path) {
      this.path = this.$route.query.path;
    }

    this.getList();
  },

  methods: {
    // 回到上一级
    reback() {
      this.$router.push({
        path: this.path
      });
    },

    getList() {
      this.$http.get(this.$api.getPressCenterInfo, {
        params: {
          linguisticType: this.linguisticType,
          pressOrder: this.ruleForm.pressOrder
        }
      }).then(res => {
        if (res.code == 0) {
          this.ruleForm = res.data[0];
          this.ruleForm.releaseDate = this.ruleForm.releaseDate.substring(0, 10);
        }
      });
    }

  }
};