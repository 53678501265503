var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content article-detail" }, [
    _c("section", { staticClass: "article-detail-content" }, [
      _c("div", { staticClass: "article-detail-text" }, [
        _c("div", { staticClass: "part-title" }, [
          _c("span", [_vm._v(_vm._s(_vm.ruleForm.headLine))])
        ]),
        _c("div", { staticClass: "article-time" }, [
          _c("span", [_vm._v(_vm._s(_vm.ruleForm.releaseDate))])
        ]),
        _c("div", {
          staticClass: "article-text",
          domProps: { innerHTML: _vm._s(_vm.ruleForm.note) }
        })
      ]),
      _c("div", { staticClass: "flex-end article-detail-breadcrumb" }, [
        _vm.linguisticType == 0
          ? _c(
              "div",
              {
                staticClass: "one-breadcrumb flex-end",
                on: { click: _vm.reback }
              },
              [
                _vm._v("返回"),
                _c("img", {
                  staticClass: "reback-icon",
                  attrs: { src: require("../assets/reback.png"), alt: "" }
                })
              ]
            )
          : _vm._e(),
        _vm.linguisticType == 1
          ? _c(
              "div",
              {
                staticClass: "one-breadcrumb flex-end",
                on: { click: _vm.reback }
              },
              [
                _vm._v("Back"),
                _c("img", {
                  staticClass: "reback-icon",
                  attrs: { src: require("../assets/reback.png"), alt: "" }
                })
              ]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }